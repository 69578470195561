@import '../../../colors.scss';

.topMenuTasksContainer {
  margin-bottom: 16px;
}

.inaccessible-tasks__container {
  padding: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.24);
}

.inaccessible-tasks__row,
.inaccessible-tasks__title {
  font-size: 12px;
  color: rgba($color: #cfd8dd, $alpha: 0.5);
}

.inaccessible-tasks__row {
  line-height: 16px;
  position: relative;
  padding-left: 28px;

  &::before {
    content: '';
    top: 50%;
    width: 4px;
    height: 4px;
    left: 16px;
    border-radius: 50%;
    position: absolute;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: #89939d;
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.inaccessible-tasks__title {
  line-height: 15px;
  max-width: 244px;
}

.inaccessible-tasks__list {
  margin: 12px 0;
}

.topMenu {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 99999;
}

.task-toggle-container {
  padding: 15px;
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% - 1px);
  border-radius: 4px;
  border: solid 1px #242c36;
  background-color: #2e3846;
}

.task-toggle-container__item {
  color: #fff;
  font-size: 14px;
  cursor: pointer;

  &.disabled {
    opacity: 0.3;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.task-toggle-container__title {
  font-family: 'LatoWebSemibold';
  font-size: 12px;
  color: rgba($color: #cfd8dd, $alpha: 0.5);
  line-height: 15px;
  margin-bottom: 15px;
}

.topMenu__col {
  display: inline-block;
  vertical-align: middle;
}

.user {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  border: solid 1px rgba(27, 36, 49, 0.9);
  background-color: #000;
  background-image: url(../../../assets/images/user.png);
  background-position: center;
  background-size: contain;
  transform: rotateY(180deg);
  transition: all 0.5s ease;

  &:hover {
    transform: rotateY(0);
  }
}

.topMenu__col {
  position: relative;

  .menu__triangle {
    &::before {
      top: -26px;
      left: 124px;
    }
  }

  .menu__container {
    transform: translateX(-100%);
    left: auto;
    left: 48px;
    padding: 20px 0;
    min-width: 164px;

    .menu__title {
      text-align: center;
      margin-top: 0;
    }
  }
}

.topMenu__opts {
  height: 40px;
  padding: 0 12px;
  border-radius: 20px;
  background-color: rgba(25, 36, 51, 0.8);
  margin-right: 16px;
}

.top-menu-opt__title {
  display: inline-block;
  vertical-align: middle;
  opacity: 0.5;
  color: #cfd8dd;
  line-height: 16px;
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 13px;
  font-family: 'LatoWebBold';
  margin-left: 5px;
  margin-top: 1px;
}

.topMenu__opts_sep-line {
  .topMenu__item {
    &:not(:first-child) {
      &::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 16px;
        border-radius: 20px;
        background-color: rgba(132, 149, 172, 0.4);
        left: -9px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .topMenu__item:not(:last-child) {
    margin-right: 17px;
  }

  .topMenu__item:last-child {
    margin-left: 0;
  }
}

.topMenu__item {
  width: 24px;
  height: 40px;
  padding: 8px 0;
  cursor: pointer;
  position: relative;
  display: inline-block;
  vertical-align: middle;

  path {
    transition: all 0.25s ease;
  }

  &.disable {
    cursor: default;
    pointer-events: none;

    path {
      fill: #656f79;
    }
  }

  &:last-child {
    &::before {
      top: 50%;
      width: 1px;
      left: -9px;
      content: '';
      height: 16px;
      position: absolute;
      border-radius: 20px;
      transform: translateY(-50%);
      background-color: rgba(132, 149, 172, 0.4);
    }
  }

  &:last-child {
    margin-left: 9px;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }

  &:hover:not(.active),
  &.active {
    path {
      fill: $tiffany;
    }
  }
}

.topMenu__modal {
  position: absolute;
}

.topMenuContainer {
  position: absolute;
  top: 40px;
  background-color: #444e5d;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);

  &.objects {
    right: 168px;
  }

  &.areas {
    right: 104px;
  }

  &.linear {
    right: 136px;
  }

  &.tasks {
    right: 63px;
  }
}

.topMenuContainerTitle {
  font-size: 12px;
  font-family: 'LatoWebMedium';
  color: rgba($color: #cfd8dd, $alpha: 0.5);
  position: relative;
  margin-bottom: 16px;
  margin-top: 16px;
  padding-left: 16px;

  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #444e5d;
    right: 7px;
    top: -22px;
    transform: rotate(45deg);
  }
}

.topMenuListItem {
  cursor: pointer;
  font-size: 14px;
  font-family: 'LatoWebMedium';
  color: #fff;
  padding: 0 12px;
  white-space: nowrap;
  position: relative;
  margin-right: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.topMenuListItemColor {
  position: absolute;
  width: 4px;
  height: 4px;
  top: 50%;
  left: 0;
  border-radius: 50%;
  transform: translateY(-50%);
}

.topMenuTaskRow {
  min-width: 244px;
  height: 48px;
  border-radius: 4px;
  background-color: #394454;
  border: solid 1px #242c36;
  padding: 9px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  cursor: pointer;
  path {
    transition: all 0.1s ease;
  }

  &.active {
    background-color: #2e3846;
    path {
      fill: $tiffany;
    }
  }
  &:hover {
    path {
      fill: $tiffany;
    }
  }
}

.topMenuTaskItem {
  margin-left: 16px;
  margin-right: 16px;
  z-index: 9;
  &.facility {
    z-index: 99;
  }
  &.disabled {
    opacity: 0.3;
    cursor: default;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.topMenuTaskIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  path {
    transition: all 0.25s ease;
  }
}

.topMenuTaskText {
  min-width: 180px;
  font-size: 14px;
  font-family: 'LatoWebMedium';
  line-height: 14px;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  width: 175px;
  white-space: normal;
}

.topMenuListContainer {
  max-height: 416px;
  height: auto;
  padding: 0 16px 16px 16px;

  .scrollarea {
    max-height: 416px;
  }

  .scrollarea-content {
    position: relative;
  }
}
