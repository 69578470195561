/* Webfont: Lato-Black */

@font-face {
  font-family: 'LatoWebBlack';
  src: url('fonts/Lato-Black.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Lato-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/Lato-Black.woff2') format('woff2'), /* Modern Browsers */
  url('fonts/Lato-Black.woff') format('woff'), /* Modern Browsers */
  url('fonts/Lato-Black.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Bold */

@font-face {
  font-family: 'LatoWebBold';
  src: url('fonts/Lato-Bold.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/Lato-Bold.woff2') format('woff2'), /* Modern Browsers */
  url('fonts/Lato-Bold.woff') format('woff'), /* Modern Browsers */
  url('fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Hairline */

@font-face {
  font-family: 'LatoWebHairline';
  src: url('fonts/Lato-Hairline.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Lato-Hairline.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/Lato-Hairline.woff2') format('woff2'), /* Modern Browsers */
  url('fonts/Lato-Hairline.woff') format('woff'), /* Modern Browsers */
  url('fonts/Lato-Hairline.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Heavy */

@font-face {
  font-family: 'LatoWebHeavy';
  src: url('fonts/Lato-Heavy.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Lato-Heavy.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/Lato-Heavy.woff2') format('woff2'), /* Modern Browsers */
  url('fonts/Lato-Heavy.woff') format('woff'), /* Modern Browsers */
  url('fonts/Lato-Heavy.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Light */

@font-face {
  font-family: 'LatoWebLight';
  src: url('fonts/Lato-Light.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Lato-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/Lato-Light.woff2') format('woff2'), /* Modern Browsers */
  url('fonts/Lato-Light.woff') format('woff'), /* Modern Browsers */
  url('fonts/Lato-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */

@font-face {
  font-family: 'LatoWebMedium';
  src: url('fonts/Lato-Medium.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/Lato-Medium.woff2') format('woff2'), /* Modern Browsers */
  url('fonts/Lato-Medium.woff') format('woff'), /* Modern Browsers */
  url('fonts/Lato-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */

@font-face {
  font-family: 'LatoWeb';
  src: url('fonts/Lato-Regular.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
  url('fonts/Lato-Regular.woff') format('woff'), /* Modern Browsers */
  url('fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Semibold */

@font-face {
  font-family: 'LatoWebSemibold';
  src: url('fonts/Lato-Semibold.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Lato-Semibold.eot?#iefix') format('embedded-opentype'), /*IE6-IE8 */
  url('fonts/Lato-Semibold.woff2') format('woff2'), /* Modern Browsers */
  url('fonts/Lato-Semibold.woff') format('woff'), /* Modern Browsers */
  url('fonts/Lato-Semibold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Thin */

@font-face {
  font-family: 'LatoWebThin';
  src: url('fonts/Lato-Thin.eot');
  /* IE9 Compat Modes */
  src: url('fonts/Lato-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/Lato-Thin.woff2') format('woff2'), /* Modern Browsers */
  url('fonts/Lato-Thin.woff') format('woff'), /* Modern Browsers */
  url('fonts/Lato-Thin.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}