@mixin bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.project-version {
  position: absolute;
  top: 114px;
  right: 48px;
  color: rgba($color: #cfd8dd, $alpha: 0.5);
  font-size: 11px;
  line-height: 13px;
}

div.__react_component_tooltip {
  &.hide {
    display: none;
  }
}

.projects {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}

.projects__item-bg {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: #3e4b5d;
  opacity: 0;
}

.projects__item {
  position: relative;
  z-index: 0;
  padding: 0 16px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    .projects__item-bg {
      opacity: 1;
    }

    .projects__col-desc-title-text {
      max-width: calc(100% - 60px);
    }

    .wellfield-icons {
      opacity: 1;
    }
  }

  .projects__col-row {
    padding: 16px 0;
  }
}

.wellfield-icons {
  position: absolute;
  top: 23px;
  right: 16px;
  opacity: 0;

  .download-icon {
    margin-right: 12px;
  }
}

.projects__col {
  display: inline-block;
  width: 500px;
  max-height: 80vh;
  vertical-align: top;
  background-color: #303a48;
  border-radius: 4px;
  box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.2);

  &:first-child {
    margin-right: 16px;
  }
}

.projects__col_left {
  padding: 0 40px;
}

.projects__icon {
  width: 340px;
  height: 60px;
  margin: 0 auto;
  background-image: url(../../assets/images/logo.svg);

  @include bg;
}

button.projects__button {
  padding: 12px 24px;
}

.projects__title-row {
  position: relative;
  padding-top: 59px;
  padding-bottom: 57px;
  border-bottom: 1px solid #242c36;
}

.projects__text {
  display: inline-block;
  margin: 0 16px;
  color: #cfd8dd;
  font-size: 14px;
  vertical-align: middle;
  opacity: 0.5;
}

.projects__bottom-row {
  padding: 39px 0 40px 0;
  text-align: center;
  border-top: 1px solid rgba(207, 216, 221, 0.18);
}

.projects__col-row {
  position: relative;
  padding: 16px;

  &::before {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #242c36;
    content: "";
  }

  &.title {
    z-index: 9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  }
}

.projects__col-img {
  display: inline-block;
  width: 96px;
  height: 80px;
  margin-right: 16px;
  vertical-align: top;
  background-image: url(../../assets/images/preview.png);
  background-size: cover;
  border-radius: 2px;
}

.projects__col-desc {
  position: relative;
  display: inline-block;
  width: calc(100% - 112px);
  height: 80px;
  padding: 8px 0;
  vertical-align: top;

  &.wellfield {
    width: 100%;
    height: 68px;
    padding: 20px 0;

    .projects__col-desc-title {
      margin-bottom: 3px;
    }

    .projects__col-date {
      position: relative;
      bottom: auto;
    }
  }
}

.projects__col-desc-title-text {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.projects__col-desc-title {
  display: flex;
  display: block;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
  color: #fff;
  font-size: 14px;
  font-family: "LatoWebMedium", sans-serif;
  font-style: normal;
  line-height: 14px;
}

.projects__col-desc-subtitle {
  color: #cfd8dd;
  font-size: 12px;
  font-family: "LatoWebMedium", sans-serif;
  font-style: normal;
  opacity: 0.8;
}

.projects__col-date {
  position: absolute;
  bottom: 8px;
  display: block;
  width: 100%;
  color: #cfd8dd;
  font-size: 11px;
  white-space: nowrap;
  opacity: 0.5;
}

.projects__container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1016px;
  transform: translate(-50%, -50%);
}

.open-projects {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 800px;
  background-color: #303a48;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -50%);

  .row-toggle__text {
    max-width: 100%;
    padding-left: 36px;
  }
}

.row-toggle.row-toggle-project {
  &:hover {
    .row-toggle__text {
      max-width: calc(100% - 80px);
      overflow: hidden;
    }
  }
}

.open-projects__top-row {
  padding: 17px;
  background-color: #39424f;
  border-bottom: 1px solid #242c36;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.open-projects__close-button {
  position: relative;
  float: right;
  width: 16px;
  height: 16px;
  cursor: pointer;

  &::after,
  &::before {
    position: absolute;
    width: 16px;
    height: 1px;
    background-color: rgba(207, 216, 221, 0.45);
    content: "";
  }

  &::after {
    top: 8px;
    left: 0;
    transform: rotate(45deg);
  }

  &::before {
    top: 8px;
    left: 0;
    transform: rotate(-45deg);
  }
}

.open-projects__title {
  float: left;
  color: #fff;
  font-size: 16px;
  font-family: "LatoWebBold", sans-serif;
}

.open-projects-search {
  height: 63px;
  padding: 16px;
  border-bottom: 1px solid #1e242d;
}

.projectsRowTitle {
  position: relative;
  padding: 9px 9px 9px 37px;
  color: #cfd8dd;
  font-size: 12px;
  font-family: "LatoWebBold", sans-serif;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.16);
  border-bottom: 1px solid #1e242d;
  cursor: pointer;

  &::after {
    position: absolute;
    top: 8px;
    left: 17px;
    width: 6px;
    height: 6px;
    border-right: 2px solid rgba(255, 255, 255, 0.4);
    border-bottom: 2px solid rgba(255, 255, 255, 0.4);
    transform: rotate(45deg);
    transition: all 0.25s ease;
    content: "";
  }

  &.hide {
    &::after {
      top: 11px;
      transform: rotate(-45deg);
    }
  }
}

.open-projects__col {
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

.openProjectImg {
  height: 535px;
  background-color: hotpink;
}

.open-project__bottom-row {
  padding: 16px;
  border-top: 1px solid #242c36;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
}

.projects-list-wrapper {
  display: block;
  max-height: 650px;
}

.projects-list-container {
  height: calc(75vh - 50px);

  &.wellfield {
    .wellfield-icons {
      top: 16px;
    }

    .projects__col-row {
      padding: 0;
    }
  }
}

.open-projects__container {
  position: relative;
}

.open-projects-first-col {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.open-projects-list {
  height: calc(100% - 63px);
}

.row-toggle__text-active {
  position: relative;

  &::after {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: 18px;
    background-color: rgba($color: #d5e6fd, $alpha: 0.3);
    transform: translateY(-50%);
    content: "";
  }
}

.open-projects__open-button {
  margin-right: 16px;
}

.new-project__main {
  .DataFieldsRow {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.projects-count {
  margin-left: 50px;
}

.draw-project-area {
  background-color: #77f4e8;
  border: 2px solid #fff;
  border-radius: 50%;
}

.info-alert-icon {
  margin-right: 12px;
  font-size: 0;
  opacity: 0;
  transition: all 0.25s ease;
}

.project-list__tooltip-text.without-data {
  display: none;
}

.project-list__tooltip-title {
  margin-bottom: 16px;
  color: #cfd8dd;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;
  opacity: 0.5;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.project-list__tooltip-text {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;

  &.waiting {
    font-size: 0;
    line-height: 0;
    animation: rotate 2s linear infinite;
  }
}

.project-list__tooltip-list {
  margin: 12px 0;
}

.project-list__tooltip-list-item {
  padding-left: 28px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.project-list__tooltip {
  position: relative;
  padding: 16px;
}

div.__react_component_tooltip.project-info-tooltip {
  max-width: none;
  padding: 16px !important;

  &::after {
    border-top-color: #192433 !important;
  }
}

.project-list__tooltip-bottom {
  position: relative;
  display: flex;
  padding: 16px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 1px;
    background: rgba(0, 0, 0, 0.28);
    content: '';
  }
}

.project-list__tooltip-bottom-col {
  &:first-child {
    margin-right: 32px;
    text-align: left;
  }

  text-align: center;

  &:nth-child(2) {
    margin-right: 16px;
  }

  .project-list__tooltip-text {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.project-info {
  position: relative;
}

.project-list__tooltip-container {
  position: fixed;
  top: 0;
  z-index: 9999;
  display: none;
  background: #192433;
  border-radius: 4px;
  transform: translateX(calc(-50% + 8px));
}

.mode-bottom {
  .project-list__tooltip-sub-container {
    &::after {
      position: absolute;
      top: auto;
      bottom: -6px;
      left: 50%;
      margin-left: -8px;
      border-top-color: #192433;
      border-top-width: 6px;
      border-top-style: solid;
      border-right: 8px solid transparent;
      border-bottom: hidden;
      border-left: 8px solid transparent;
      content: '';
    }
  }
}

.project-list__tooltip-sub-container {
  position: relative;

  &::after {
    position: absolute;
    top: -6px;
    bottom: auto;
    left: 50%;
    margin-left: -8px;
    border-top: hidden;
    border-right: 8px solid transparent;
    border-bottom-color: #192433;
    border-bottom-width: 6px;
    border-bottom-style: solid;
    border-left: 8px solid transparent;
    content: '';
  }
}
