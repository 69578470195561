.modal-content__row {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  width: 460px;
  background: #303A48;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.modal-content {
  padding: 16px;
}
.modal-container__top-row {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.modal-content__title {
  width: 153px;
  font-size: 14px;
  line-height: 18px;
  margin-right: 16px;
  display: inline-block;
  vertical-align: middle;
  color: rgba(207, 216, 221, 0.5);
}

.modal-content__subtitle {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  display: inline-block;
  vertical-align: middle;
}

.main-container__bottom-row {
  padding: 16px;
  border-top: 1px solid #242C36;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
}


.new-wellfield__help {
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 432px;
  height: 238px;
  position: absolute;
  border-radius: 4px;
  box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #303a48;
}
.new-wellfield__confirm {
  width: 432px;
  height: 204px;
}
.new-wellfield__p {
  color: #FFFFFF;
  font-size: 14px;
  padding-right: 5px;
  margin-bottom: 23px;
}
.new-wellfield__confirm__p {
  padding-right: 5px;
  margin-bottom: 0px;
  line-height: 16px;
}
.new-wellfield__confirm__p__bottom {
  margin-bottom: 7px;
}