.login {
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #303a48;
  padding: 40px 60px 60px;
  min-width: 360px;
  .DataFields {
    margin-bottom: 16px;
    line-height: 24px;
    padding-left: 14px;
  }
  .login__button {
    margin-top: 16px;
    width: 100%;
    line-height: 24px;
  }
  .login-input_container {
    display: block;
  }
}