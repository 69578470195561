@import "./assets/latofonts.css";
@import "./colors";

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  border: 0;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

html,
body {
  width: 100%;
  height: 100%;
  min-height: 680px;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
}

#root,
#map,
#mapUI,
.main-page {
  width: inherit;
  height: inherit;
  background-color: #f5f2ed;
}

.filterSubwayLines {
  position: absolute;
  top: 10px;
  z-index: 10;
  width: 200px;
  height: auto;
  margin: 10px;
  padding: 5px;
  padding-bottom: 10px;
  font-size: 13px;
  font-family: sans-serif;
  background: hsla(0, 100%, 100%, 0.8);
  border-radius: 5px;
}

.filterSubwayLines hr {
  margin: 5px;
  border: 1px solid #000;
}

.filterSubwayLines h3 {
  margin-top: 0;
  padding-left: 5px;
}

.filterSubwayLines p {
  padding-left: 5px;
}

.filterSubwayLines select {
  display: block;
  margin: 0 auto;
}

.sr-only {
  display: none;
}

html,
body,
div,
span,
button,
input,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font-family: "LatoWeb", serif;
  -webkit-font-smoothing: subpixel-antialiased;
}

input {
  font-family: "LatoWeb", serif;
  -webkit-font-smoothing: subpixel-antialiased;

  &::placeholder {
    color: rgba(207, 216, 221, 0.5);
    font-family: "LatoWeb", serif;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  &:-ms-input-placeholder {
    color: rgba(207, 216, 221, 0.5);
    font-family: "LatoWeb", serif;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  &::-ms-input-placeholder {
    color: rgba(207, 216, 221, 0.5);
    font-family: "LatoWeb", serif;
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

#root,
#mapUI {
  min-width: 1100px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;

  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;

  /* <-- Apparently some margin are still there even though it's hidden */
}

/* Hide HTML5 Up and Down arrows. */

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

input::-ms-clear {
  display: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:active {
    color: inherit;
  }
}

.bold {
  color: inherit;
  font-size: inherit;
  font-family: "LatoWebBold";
}

.flex-column {
  flex-direction: column;
}

.d-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.justify-between {
  justify-content: space-between;
}

.new-project {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 999;
}

.remove-alert__content {
  width: 100%;
  margin-top: 14px;
  padding: 14px 16px 12px 16px;
  background: #444e5d;
  border-radius: 2px;
}

.remove-alert__content-title {
  color: rgba(207, 216, 221, 0.5);
  font-size: 14px;
  font-family: "LatoWebMedium";
  line-height: 16px;
  white-space: nowrap;
}

.remove-alert__content-type,
.remove-alert__content-title {
  margin-top: 1px;

  &:not(:last-child) {
    margin-bottom: 7px;
  }
}

.remove-alert__content-type {
  max-width: 260px;
  overflow: hidden;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  white-space: normal;
  text-overflow: ellipsis;

  &.object-type::first-letter {
    text-transform: capitalize;
  }
}

.remove-alert__content-col {
  display: inline-block;
  vertical-align: top;

  &:first-child {
    padding-right: 12px;
  }
}

.new-project__main {
  .css-1aya2g8 {
    background-color: #202a38;
    border: none;
    border-radius: 2px;
  }
}

.card-bottom {
  padding: 14px 15px 15px;
  border-top: 1px solid #242c36;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);

  button {
    min-width: 80px;
  }
}

.new-project__create {
  background-color: #3e4b5d;
}

.new-project__cancel {
  border: solid 1px #4b596d;
}

.new-project__button {
  &:not(:last-child) {
    margin-right: 16px;
  }
}

.areaRow {
  height: 140px;
}

.new-project__first {
  position: relative;
  padding-left: 24px;

  &::before {
    position: absolute;
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    content: "";
  }
}

.new-project__area {
  min-height: 60px;
  padding: 20px;
  color: rgba(170, 192, 221, 0.5);
  font-size: 14px;
  font-family: "LatoWebMedium";
  text-align: center;
  border: dashed 1px rgba(170, 192, 221, 0.4);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.5s ease;

  &.middle {
    padding-top: 45px;
    padding-bottom: 45px;
    border: solid 1px rgba(170, 192, 221, 0.2);
  }

  &.last {
    background-color: #202a38;
    border-color: transparent;
  }
}

.leaflet-control-container {
  .leaflet-left {
    right: 0;
    left: auto;
  }
}

.new-project__tooltip {
  position: fixed;
  right: 8px;
  bottom: 8px;
  width: 390px;
  height: 58px;
  padding: 14px 18px 14px 40px;
  background-color: #202a38;
  border-radius: 4px;
}

.new-project__tooltip-text {
  position: relative;
  color: #8f9fb6;
  font-size: 14px;
  line-height: 17px;

  &::before {
    position: absolute;
    top: 0;
    left: -28px;
    width: 20px;
    height: 20px;
    background-color: hotpink;
    content: "";
  }
}

.createRoad {
  div.facility-pin {
    pointer-events: none;
  }

  div.leaflet-div-icon {
    background-color: rgb(255, 186, 86);
  }

  .leaflet-middle-icon {
    display: none;
  }

  .leaflet-popup a.leaflet-popup-close-button {
    top: 50%;
    width: 12px;
    height: 12px;
    transform: translateY(-50%);
  }
}

.ruler {
  div.facility-pin {
    pointer-events: none;
  }

  div.leaflet-div-icon {
    background-color: #8495ac;
  }

  .leaflet-middle-icon {
    display: none;
  }

  .leaflet-popup-content-wrapper {
    background-color: #444e5d;

    .leaflet-popup-content {
      width: auto !important;
      margin: 7px 34px 7px 12px;

      p {
        margin: 0;
      }
    }
  }

  .leaflet-popup a.leaflet-popup-close-button {
    top: 50%;
    width: 12px;
    height: 12px;
    transform: translateY(-50%);
  }
}

.createLinearObject {
  .leaflet-middle-icon {
    display: none;
  }

  div.facility-pin {
    pointer-events: none;
  }

  div.leaflet-div-icon {
    background-color: #ffba56;
  }
}

div.ruler-marker {
  width: 12px !important;
  height: 12px !important;
  margin-top: -6px !important;
  margin-left: -6px !important;
  background-color: #8495ac;
  border: solid 2px #fff;
  border-radius: 50%;
  outline: none;
  transition: border 0.2s ease, background 0.2s ease;

  &.active-marker {
    background-color: #fd603c;

    &:hover {
      background: #fd603c;
    }
  }

  &:hover {
    width: 14px;
    height: 14px;
    background-color: transparent;
    background-color: #fff;
    background-image: url(./assets/images/map_zoom_in.svg);
    border-color: transparent;
  }
}

.search-input {
  width: calc(100% - 23px);
  height: 32px;
  padding: 7px 10px;
  color: #fff;
  background-color: #202a38;
  border: none;
  outline: none;
}

.search-filter-logo {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 15px;
  vertical-align: middle;
  background-image: url(./assets/images/filter.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.search-logo {
  display: inline-block;
  width: 15px;
  height: 15px;
  vertical-align: middle;
  background-image: url(./assets/images/search.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.search-input-container {
  background-color: #202a38;
  border-radius: 2px;
}

/*
 * Container style
 */

.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  -ms-touch-action: auto;
  touch-action: auto;
}

/*
 * Scrollbar rail styles
 */

.ps__rail-x {
  /* please don't change 'position' */
  position: absolute;

  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0;
  display: none;
  height: 15px;
  opacity: 0;
}

.scrollbar-container .ps__rail-y {
  /* please don't change 'position' */
  position: absolute;

  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;

  &::after {
    background-color: transparent;
  }
}

.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */

.ps__thumb-x {
  /* please don't change 'position' */
  position: absolute;

  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  height: 6px;
  background-color: #aaa;
  border-radius: 6px;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
}

.ps__thumb-y {
  /* please don't change 'position' */
  position: absolute;

  /* there must be 'right' for ps__thumb-y */
  right: 6px;
  width: 4px;
  background: #202a38;
  border-radius: 4px;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;

  /* height: 11px; */
}

/* MS supports */

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

.leaflet-control-attribution.leaflet-control,
.leaflet-draw.leaflet-control,
.leaflet-control-zoom.leaflet-bar.leaflet-control {
  display: none;
}

@keyframes shadowAnim {
  0% {
    box-shadow: 0 0 94px 13px rgba(102, 255, 0, 1);
  }

  100% {
    box-shadow: 0 0 94px 13px #fff701;
  }
}

.login__logo {
  width: 132px;
  height: 80px;
  margin: 0 auto 40px;
  background-image: url(./assets/images/logo_login.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.new-project__close {
  position: absolute;
  top: 50%;
  right: 17px;
  z-index: 9;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
  cursor: pointer;
}

.new-project__close-line {
  width: 100%;
  height: 100%;
}

.pipe-arrow {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-image: url(./assets/images/arrow_for_pipe.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: rotate(-90deg);
    content: "";
  }
}

.custom-icon {
  background-color: #49b4ff;
}

.spinner {
  position: relative;
  z-index: 99999999;
  width: 40px;
  height: 40px;
  margin: 100px auto;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  position: absolute;
  top: 0;
  display: inline-block;
  width: 60%;
  height: 60%;
  background-color: #fff;
  border-radius: 100%;
  -webkit-box-shadow: 3px -1px 104px -16px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 3px -1px 104px -16px rgba(0, 0, 0, 0.47);
  box-shadow: 3px -1px 104px -16px rgba(0, 0, 0, 0.47);
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.main-page__spinner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  background-color: rgba(49, 62, 80, 0.4);
}

.hide {
  .leftSideBar__title_text.shadow {
    color: #000;
    text-shadow: none;
  }
}

.leaflet-control-scale-line:last-child {
  display: none;
}

.leaflet-popup-content-wrapper {
  color: #fff;
  font-size: 14px;
  font-family: "LatoWebMedium";
  background-color: #444e5d;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3), 0 2px 4px 0 rgba(0, 0, 0, 0.16);

  .popup-content {
    padding: 16px 32px 16px 16px;
  }

  .leaflet-popup-content {
    width: auto !important;
    margin: 0;
    line-height: 14px;

    p {
      margin: 0;
      line-height: 16px;
      white-space: nowrap;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .popup-added-props {
      margin-bottom: 5px;
    }
  }
}

.leaflet-popup-tip {
  background-color: #444e5d;
}

.leaflet-popup a.leaflet-popup-close-button {
  top: 20px;
  right: 12px;
  width: 10px;
  height: 10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background-image: url(./assets/images/close.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.popup-cirle {
  display: inline-block;
  width: 4px;
  height: 4px;
  margin-right: 5px;
  vertical-align: middle;
  border-radius: 50%;
}

.popup-class-name {
  color: rgba($color: #cfd8dd, $alpha: 0.5);
  font-size: 12px;
  font-family: "LatoWebSemibold";

  &::first-letter {
    text-transform: uppercase;
  }
}

.popup-added-props {
  color: #cfd8dd;
  font-size: 12px;
  font-family: "LatoWebSemibold";
}

.leaflet-control-scale.leaflet-control {
  margin-bottom: 8px;
}

.leaflet-control-scale-line {
  margin-right: 8px;
  padding: 4px 0;
  color: #fff;
  font-size: 10px;
  font-family: "LatoWebBold";
  text-align: center;
  background-color: rgba(25, 36, 51, 0.8);
  border: none;
  border-radius: 2px;
}

.custom-pin div {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.d-inline {
  display: inline-block;
}

.align-middle {
  vertical-align: middle;
}

.task-complete {
  position: absolute;
  right: 8px;
  bottom: 8px;
  z-index: 9999;
  width: calc(100% - 474px);
  height: 308px;
  overflow: hidden;
  background-color: #303a48;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
}

.task-complete__toggle-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #242c36;
}

.task-complete__toggle-button {
  padding: 8px 12px;
  color: #8495ac;
  font-size: 14px;
  font-family: "LatoWebSemibold";
  line-height: 16px;
  transition: all 0.25s ease;

  &.active {
    color: #fff;
    background-color: #27303c;
    border-radius: 4px;
    box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.12);
  }
}

.task-complete__row {
  border-bottom: 1px solid #242c36;
}

.task-complete__table-cell {
  position: relative;
  padding: 8px 16px;
  color: #fff;
  font-size: 14px;
  font-family: "LatoWebSemibold";

  &:first-child {
    border-left: 1px solid #242c36;
  }

  .line {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;

    &.line_initial-color {
      &.color-0 {
        background-color: #f5a623;
      }

      &.color-1 {
        background-color: #6b4eff;
      }

      &.color-2 {
        background-color: #ff3cdd;
      }

      &.color-3 {
        background-color: #a000ff;
      }

      &.color-4 {
        background-color: #6b4eff;
      }
    }

    &.line_optimal-color {
      background-color: #35da67;
    }
  }

  &:first-child {
    padding-left: 20px;
  }

  &:not(:first-child) {
    text-align: right;
  }

  &:not(:last-child) {
    border-right: 1px solid #242c36;
  }
}

.task-complete__col:not(:last-child) {
  border-right: 1px solid #242c36;
}

.task-complete__table-title-text {
  font-family: "LatoWebSemibold";
  opacity: 0.5;
}

.task-complete__table-title {
  padding: 8px 16px;
  color: #cfd8dd;
  font-size: 14px;
  text-align: left;
  background-color: #394454;

  &:first-child {
    padding-left: 20px;
  }

  &:not(:last-child) {
    border-right: 1px solid #242c36;
  }
}

.task-complete__progress {
  float: right;
  color: #629de2;
  font-size: 14px;
  font-family: "LatoWebBold";

  &.done {
    color: #77f4a2;
  }

  &.fail {
    color: #fd603c;
  }
}

.task-complete__logs-row {
  padding: 8px 16px;
}

.task-complete__logs__left {
  display: inline-block;
}

.task-complete__date {
  margin-right: 16px;
  color: #8495ac;
  font-size: 14px;
}

.task-complete__name {
  color: #fff;
  font-size: 14px;
}

.task-complete__table {
  width: 100%;
}

.task-complete__buttons {
  padding-top: 64px;
  text-align: center;
}

.task-complete__button {
  margin-left: 16px;
}

.task-complete__label {
  margin: 0 16px;
  color: #cfd8dd;
  font-size: 14px;
}

.error-list,
.react-notification-root {
  position: absolute;
  top: 8px;
  left: 50%;
  z-index: 999;
  display: flex;
  flex-flow: column-reverse;
  max-height: calc(100vh - 8px);
  overflow-y: hidden;
  transform: translateX(-50%);
}

.logs_alert {
  position: relative;
  margin-bottom: 10px;
  color: #fff;
  font-size: 14px;
  background-color: rgba(25, 36, 51, 0.9);
  border-radius: 2px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 2px;
    content: "";
  }

  &.info {
    &::after {
      background-color: #629de2;
    }
  }

  &.warning {
    &::after {
      background-color: #f5c023;
    }
  }

  &.success {
    &::after {
      background-color: #77f4a2;
    }
  }

  &.errors {
    &::after {
      background-color: #fd603c;
    }
  }
}

.logs__desc {
  padding: 13px 16px 11px 36px;
}

.logs__icon {
  position: absolute;
  top: 12px;
  left: 13px;
  display: block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  &.info {
    background-image: url(./assets/images/alert_info.svg);
  }

  &.warning {
    background-image: url(./assets/images/alert_warning.svg);
  }

  &.errors {
    background-image: url(./assets/images/alert_error.svg);
  }

  &.success {
    background-image: url(./assets/images/alert_success.svg);
  }
}

.example-enter {
  max-height: 0;
  padding: 0;
  overflow: hidden;
  transform: translateY(-100%);
  opacity: 0;
}

.example-enter.example-enter-active {
  max-height: 50px;
  transform: translateY(0%);
  opacity: 1;
  transition: all 500ms linear;
}

.example-leave {
  max-height: 0;
  padding: 0;
  transform: translateY(100%);
  opacity: 1;
}

.example-leave.example-leave-active {
  transform: translateY(0%);
  opacity: 0;
  transition: all 500ms linear;
}

.pow {
  vertical-align: super;
}

.leaflet-path-draggable.leaflet-interactive {
  & + .leaflet-interactive {
    pointer-events: none;
    fill: #fff;
    stroke: #fff;
    fill-opacity: 0.2;
    stroke-dasharray: 0;
    stroke-width: 3;
  }
}

.scrollarea.quarries-results {
  display: flex;
  height: 259px;

  & > div {
    display: flex;
    padding: 16px;
  }
}

.quarries-results__row {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.quarries-results__title {
  color: rgba(207, 216, 221, 0.5);
}

.quarries-results__value {
  color: #fff;
  text-align: right;
}

.quarries-results__title,
.quarries-results__value {
  font-size: 14px;
  line-height: 18px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.quarries-results__sub-col {
  display: inline-block;

  &:first-child {
    margin-right: 16px;
  }
}

.quarries-results__col {
  display: inline-block;
  vertical-align: top;

  &:first-child {
    margin-right: 80px;
  }
}

.task-results {
  height: calc(100% - 42px);

  & > div {
    display: flex;
  }
}

.linear-task-container {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: calc(100% - 42px);
}

.linear-task-container__buttom-row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
}

.connection-point {
  background-color: #38424f;
  border: 2px solid #38424f;
}

.handle-task-results {
  display: flex;
  align-items: center;

  .DataFieldsRow {
    display: block;
    width: 240px;
  }
}

.linear-task-container__title {
  padding: 8px 16px;
  background-color: #394454;
  border-right: 1px solid #242c36;
  border-bottom: 1px solid #242c36;

  &.height {
    height: 64px;
    min-height: 64px;
  }
}

.linear-task-container__title-text {
  color: #cfd8dd;
  font-size: 14px;
  font-family: "LatoWebSemibold";
  white-space: nowrap;
  opacity: 0.5;
}

.linear-task-container__text {
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
}

.linear-task-container__cell {
  padding: 8px 16px;
  border-right: 1px solid #242c36;
  border-bottom: 1px solid #242c36;
}

.linear-task-container__sub-title {
  display: flex;
  min-height: 32px;
  padding: 8px 16px;
  line-height: 14px;
  background-color: #394454;
  border-right: 1px solid #242c36;
  border-bottom: 1px solid #242c36;
}

.linear-task-container__col {
  display: flex;
  flex-basis: 100%;
  flex-flow: column;
  flex-grow: 1;
  line-height: 14px;
}

.linear-task-container__col:not(:first-child) {
  .linear-task-container__cell {
    text-align: right;
  }
}

.linear-task-results {
  display: flex;
}

.handle-task-results__text {
  margin-right: 8px;
  color: #cfd8dd;
  font-size: 14px;
  line-height: 16px;
  opacity: 0.5;
}

.notification-container-top-right {
  width: 540px;
}

.ps--active-y {
  .ps__rail-y {
    opacity: 1;
  }
}

.ps__rail-y {
  z-index: 999;
  float: right;
  padding: 16px 0;
  border-radius: inherit;
  cursor: pointer;
  opacity: 0;
}

.new-wellfield {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9999;
  width: 700px;
  background-color: #303a48;
  border-radius: 4px;
  box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -50%);

  .new-project__bottom {
    padding: 16px;
  }
}

.new-wellfield__input-container {
  width: 368px;
}

.dropzone {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  background: rgba(170, 192, 221, 0.04);
  outline: none;
  cursor: pointer;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: dashed 1px rgba(170, 192, 221, 0.4);
    border-radius: 4px;
    content: "";
  }
}

.dropzone__results {
  background: #303a48;
  cursor: default;

  &::after {
    border: solid 1px rgba(170, 192, 221, 0.2);
  }
}

.dropzone__text {
  color: #aac0dd;
  font-size: 14px;
  font-family: "LatoWebSemibold";
}

.new-wellfield__tip {
  margin-top: 8px;
  color: rgba(132, 149, 172, 0.8);
  font-size: 14px;
}

.new-wellfield__tip_semibold {
  font-family: "LatoWebBold";
}

.new-wellfield__help-button {
  float: right;
}

.projects {
  overflow: hidden;
}

.remove-icon_wellfield {
  margin-right: 8px;
}

.remove-wellfield-alert {
  top: 10px;
  z-index: 9999;
}

.snow-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
}

.leaflet-top,
.leaflet-bottom {
  position: fixed;
  z-index: 9998;
}

.sublayer.network {
  .field-row__remove {
    display: none;
  }

  .set-view {
    margin-right: 8px;
  }
}

.hidden-textarea {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  outline: none;
  cursor: default;
  opacity: 0;
  pointer-events: none;
}

@import "./main.scss";
