@import "../../../colors.scss";

.left-sidebar__bottom {
  &.objectInfoCard,
  &.objects {
    .sideButton.objects {
      border-radius: 4px;
      background-color: #27303c;
      box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.12);

      path {
        fill: $tiffany;
      }
    }
  }

  &.linearInfoCard,
  &.linear {
    .sideButton.linear {
      border-radius: 4px;
      background-color: #27303c;
      box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.12);

      path {
        fill: $tiffany;
      }
    }
  }

  &.areaInfoCard,
  &.areas {
    .sideButton.areas {
      border-radius: 4px;
      background-color: #27303c;
      box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.12);

      path {
        fill: $tiffany;
      }
    }
  }

  &.tasks,
  &.roads,
  &.taskInfo,
  &.quarries,
  &.oil_pipe,
  &.linear_cost,
  &.gathering_centers,
  &.facility_placement,
  &.oil_gathering_line,
  &.high_pressure_water_pipe,
  &.low_pressure_water_pipe,
  &.high_pressure_gas_pipe,
  &.low_pressure_gas_pipe {
    .sideButton.tasks {
      border-radius: 4px;
      background-color: #27303c;
      box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.12);

      path {
        fill: $tiffany;
      }
    }
  }
}

.sideButton {
  display: block;
  padding: 4px;
  font-size: 0;
  width: 32px;
  height: 32px;
  background-size: 24px 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent;
  transition: all 0.25s ease-in-out;

  path {
    fill: #fff;
    transition: all 0.25s ease;
  }

  &:hover {
    path {
      fill: $tiffany;
    }
  }

  &:not(:last-child) {
    margin-bottom: 18px;
  }
}
