@import '../../colors.scss';

.rightMenu {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
}

.right-menu__handlers {
  float: right;

  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
}

.rightMenuZoom {
  width: 32px;
  border-radius: 16px;
  background-color: rgba(25, 36, 51, 0.8);
  padding: 10px 8px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 16px;
    top: 50%;
    left: 8px;
    height: 1px;
    background-color: rgba(132, 149, 172, 0.4);
  }
}

.move-to-section {
  background-image: url(../../assets/images/moveToSelection.svg);
}

.rightMenuZoomIcon {
  display: block;
  font-size: 22px;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;

  &.in {
    background-image: url(../../assets/images/map_zoom_in.svg);
    margin-bottom: 16px;
  }

  &.out {
    margin-top: 16px;
    background-image: url(../../assets/images/map_zoom_out.svg);
  }
}

.rightMenuIcon {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: rgba(25, 36, 51, 0.8);
  background-repeat: no-repeat;
  background-position: center;
  padding: 8px;
  position: relative;
  cursor: pointer;

  &.disable {
    cursor: default;
    pointer-events: none;

    path {
      fill: #656F79
    }
  }
}

.map-type {
  position: absolute;
  background-color: #444e5d;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  padding: 16px;
  right: calc(100% + 10px);
  width: 215px;
  border-radius: 4px;
  cursor: default;
  top: 0;

  &::after {
    content: '';
    position: absolute;
    width: 17px;
    height: 17px;
    top: 8px;
    right: -4px;
    transform: rotate(45deg);
    background-color: #444e5d;
  }
}

.map-type__title {
  font-size: 12px;
  opacity: 0.5;
  font-family: 'LatoWebMedium';
  color: #cfd8dd;
  margin-bottom: 18px;
}

.layers {
  background-size: 14px 14px;
  background-image: url(../../assets/images/map_layers.svg);
}

.threeD {
  background-size: 14px 14px;
  background-image: url(../../assets/images/map_3D_mode.svg);
}

.radioInput {
  display: none;
}

.radioInputLabel {
  font-size: 14px;
  font-family: 'LatoWebMedium';
  letter-spacing: 0.3px;
  color: #fff;
  padding-left: 26px;
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background-color: #202a38;
  }

  &::after {
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    position: absolute;
    width: 0px;
    height: 0px;
    border-radius: 50%;
    background-color: #76bbb5;
    transition: all 0.25s ease;
  }
}

.optionRow {
  &:not(:last-child) {
    margin-bottom: 17px;
  }
}

.radioInput {
  &:checked + label {
    &::after {
      width: 6px;
      height: 6px;
    }
  }
}

.map-type__bottom {
  margin-top: 15px;
  padding-top: 19px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -16px;
    left: -16px;
    height: 1px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.24);
  }
}

.rightMenuIcon.ruler {
  svg {
    pointer-events: none;
  }

  &.active {
    polygon {
      fill: $tiffany;
    }
  }
}