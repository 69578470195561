#mapUI {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.splash-polygon.leaflet-interactive {
  cursor: default;
  fill: #fff;

  &.create {
    stroke-dasharray: 5, 10;
    stroke-dashoffset: 5;
    fill-opacity: 0.2;
    stroke: #fff;
  }
}

div.connection-point {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 3px;
    height: 2px;
  }
}

div.connection-points-popup {
  left: auto !important;
  right: 0;
}

.for-hover {
  stroke: transparent;
  fill-opacity: 0;
}

.leaflet-interactive {
  transition: stroke 0.15s ease-in-out, stroke-width 0.15s ease-in-out;

  &.existing {
    &.roads {
      &:hover,
      &.active {
        stroke: rgba(255, 220, 170, 0.7) !important;
      }
    }

    &.forest_corridor {
      &:hover,
      &.active {
        stroke: #b2dd7b !important;
      }
    }

    &.oil_pipe_line {
      &:hover,
      &.active {
        stroke: rgba(255, 154, 131, 0.7) !important;
      }
    }

    &.high_pressure_water_pipe,
    &.low_pressure_water_pipe {
      &:hover,
      &.active {
        stroke: rgba(146, 210, 255, 0.7) !important;
      }
    }

    &.high_pressure_gas_pipe {
      &:hover,
      &.active {
        stroke: rgba(255, 234, 155, 0.7) !important;
      }
    }
  }

  &.calculated {
    &.roads {
      &:hover,
      &.active {
        stroke: #ffdcaa !important;
      }
    }

    &.oil_pipe_line {
      &:hover,
      &.active {
        stroke: #ff9a83 !important;
      }
    }

    &.high_pressure_water_pipe,
    &.low_pressure_water_pipe {
      &:hover,
      &.active {
        stroke: #92d2ff !important;
      }
    }

    &.high_pressure_gas_pipe {
      &:hover,
      &.active {
        stroke: #ffea9b !important;
      }
    }
  }

  &.for-hover {
    &.active:not(.hideTaskFacilities) {
      stroke: white;
      fill-opacity: 1;
      stroke-width: 6;
    }

    pointer-events: none !important;
  }

  &.areas {
    &:hover,
    &.active {
      stroke-width: 2px !important;
    }
  }
}

.start-page {
  display: inline-flex;
  vertical-align: middle;
  align-items: stretch;
  position: relative;

  &.topMenu__opts {
    padding: 4px;
  }
}

.active-projects {
  .start-page__bg {
    left: 4px;
  }
}

.active-wellfields {
  .start-page__bg {
    left: 102px;
    width: 147px;
  }
}

.start-page__bg {
  position: absolute;
  width: 98px;
  top: 4px;
  height: 32px;
  border-radius: 20px;
  transition: left 0.2s ease-in-out, width 0.2s ease-in-out 0.1s;
  background-color: #72859e;
}

.start-page__button {
  z-index: 9;
  color: #fff;
  overflow: hidden;
  position: relative;
  font-size: 14px;
  line-height: 14px;
  border-radius: 20px;
  padding: 0 20px;
  cursor: pointer;
  font-family: 'LatoWebSemibold', serif;
}

.to-card-button {
  width: 100%;
  color: #8495ac;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  font-family: 'LatoWebMedium', serif;
  padding: 12px 16px 12px 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.24);
  position: relative;
  white-space: nowrap;

  &::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../../../assets/images/properties-icon.svg);
  }
}

.leaflet-interactive.hide {
  opacity: 0;
}

.select-facilities .facility {
  opacity: 0.5;

  &.facility-for-select {
    opacity: 1;
  }
}

.facility {
  &.second-selected {
    fill: #ff3cdd;
    stroke: #ff3cdd;
  }

  &.first-selected {
    fill: #77f4e8;
    stroke: #77f4e8;
  }
}

.new-project-facility {
  opacity: 0.7;
}
.linear,
.facility-with-tmp-color {
  &.tmpColor0 {
    fill: #f5a623;
  }

  &.tmpColor1 {
    fill: #6b4eff;
  }

  &.tmpColor2 {
    fill: #ff3cdd;
  }

  &.tmpColor3 {
    fill: #a000ff;
  }

  &.tmpColor4 {
    fill: #a000ff;
  }

  &.tmpColor5 {
    fill: rgb(255, 105, 180);
  }
}
